import { Footer, Header, SEO, useI18next } from '@/components'
import LinkForShare from '@/components/LinkForShare'
import CheckIcon from '@mui/icons-material/Check'
import { Box, Input, useMediaQuery } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useLocation } from '@reach/router'
import { useRequest } from 'ahooks'
import { Button, message } from 'antd'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { default as React, useEffect, useRef, useState } from 'react'
import DocumentMeta from 'react-document-meta'

export const BlogDetail = React.memo((props) => {
  const {} = useTranslation()
  const location = useLocation()
  const { navigate } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const imilePost = props.data?.imilePost
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const { enqueueSnackbar } = useSnackbar()
  const [meta] = useState(() => {
    return {
      property: {
        'og:title': imilePost?.searchTitle,
        'og:url': location.href,
        'og:image': imilePost?.detailImageUrl,
        'og:description': 'iMile',

        'twitter:card': 'summary_large_image',
        'twitter:title': imilePost?.searchTitle,
        'twitter:description': '',
        'twitter:image': imilePost?.detailImageUrl
      }
    }
  })


  const id = props?.location?.state?.id || location.search.replace(/\?id=/i, '')
  const title = props?.location?.state?.title

  const [dataBlog, setDataBlog] = useState([])
  const [sendBtnStatus, setBtnStatus] = useState(false)
  const [subscribeStatus, setSubscribeStatus] = useState(false)
  const [email, setEmail] = useState('')
  const emailInput = useRef(null)

  const getBlogs = async (data = {}) => {
    const url = `/flash/show/getBlogs`
    return window
      .fetch(url, {
        method: 'POST',
        headers: {
          lang: Cookies.get('EXPRESS_LANG'),
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          currentPage: 1,
          showCount: 2,
          trimId: id,
          ...data,
        }),
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  const getListBlogs = useRequest(getBlogs, {
    onSuccess: (res) => {
      if (res?.resultObject) {
        const data = res?.resultObject
        setDataBlog(data.results)
      } else if (res?.status === 'success' && !res?.resultObject) {
        enqueueSnackbar('no data')
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
    onError: (err) => {
      console.log(1, err)
    }
  })

  const getText = (str) => {
    return str.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '')
  }

  const apiSubscribe = async (email) => {
    const url = `/flash/subscribe?email=${email}`
    return window
      .fetch(url, {
        method: 'post',
        headers: {
          lang: Cookies.get('EXPRESS_LANG'),
        },
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  const { run: sendSubscribe } = useRequest(apiSubscribe, {
    manual: true,
    debounceInterval: 1000,
    throttleInterval: 1000,
    onSuccess: (res, params) => {
      if (res?.status === 'success') {
        setSubscribeStatus(true)
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
    onError: (err) => {
      console.log(1, err)
    }
  })


  const Subscribe = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          bottom: '105px',
          bgcolor: ['#e6e6ef'],
          height: ['150px', '200px'],
          mt: [0, '80px'],
          '.tit': {
            fontSize: !isPc ? '16px' : '28px',
            fontWeight: 600,
            color: '#000',
            textAlign: 'center',
            p: !isPc ? '20px 0' : '0 0 16px',
          },
          '.img': {
            width: ['43px', '66px'],
            height: ['67px', '86px'],
          },
          '.left': {
            marginInlineEnd: ['-28px', '-32px'],
          },
          '.right': {
            marginInlineStart: ['-26px', '-32px'],
            mt: ['24px', '35px'],
          },
          width: ['100vw', '100vw'],
          ...props.homebannersx,
        }}
      >
        <div className='tit'>{pageLocale.subscribeNewsLetter}</div>

        <Box
          sx={{
            position: 'relative',
            bgcolor: '#fff',
            '--height': ['48px', '60px'],
            width: ['325px', '600px', '650px', '800px'],
            height: 'var(--height)',
            lineHeight: 'var(--height)',
            borderRadius: ['30px'],
            padding: '0 8px 0 20px',
            input: {
              color: '#7c7d78',
              fontSize: ['16px', '20px'],
              fontWeight: [700, 900],
              '&::placeholder': {
                color: '#7c7d78',
                fontSize: ['16px', '20px'],
                fontWeight: 500,
              },
            },
            '.MuiInput-underline': {
              '&:after, &:before': {
                display: 'none',
              },
              width: '100%',
              height: 'var(--height)',
            },
          }}
        >
          <Input
            inputRef={emailInput}
            onChange={(e) => {
              setEmail(e.target.value)
              setBtnStatus(() => {
                return e.target.value
                // &&
                // /^([A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4})([,，]([A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}))*$/.test(
                //   e.target.value,
                // )
              })
            }}
            placeholder={'Email'}
            value={email}
          />

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              '--height': ['48px', '60px'],
              height: 'var(--height)',
              lineHeight: 'var(--height)',
              width: ['97px', '193px'],
              borderRadius: '30px',
              background: sendBtnStatus ? '#095ef7' : '#CFCFDD',
              fontWeight: [700, 700],
              fontSize: [14, 18, 20, 24, 26],
              textAlign: 'center',
              marginInlineStart: 'auto',
              color: '#fff',
              '.MuiTouchRipple-root': {
                background: '#fff303',
                color: '#000',
              },
              '&:hover': {
                cursor: sendBtnStatus ? 'pointer' : 'not-allowed',
              },
            }}
            onClick={(e) => {
              if (sendBtnStatus) {
                if (
                  /^([A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4})([,，]([A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}))*$/.test(
                    emailInput.current.value,
                  )
                ) {
                  sendSubscribe(emailInput.current.value)
                } else {
                  message.error({
                    content: pageLocale.emailError,
                    duration: 2,
                  })
                }
              }
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                zIndex: 1,
                height: ['48px', '60px'],
                lineHeight: ['48px', '60px'],
              }}
            >
              {pageLocale.subscribe}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const SubscribeSuccess = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200px',
          background: '#00CE8F',
          div: {
            '&:first-child': {
              color: '#00CE8F',
              height: '36px',
              width: '36px',
              borderRadius: '50%',
              textAlign: 'center',
              background: '#fff',
              lineHeight: '48px',
              marginRight: '8px',
            },
            '&:last-child': {
              color: '#fff',
              fontSize: '28px',
              fontWeight: '600',
            },
          },
        }}
      >
        <div>
          <CheckIcon />
        </div>
        <div>{pageLocale.subscribed}</div>
      </Box>
    )
  }


  useEffect(() => {
    setEmail(email)
    emailInput.current.focus()
  }, [email])


    useEffect(() => {
      if (id) {
        getListBlogs.run({ trimId: id })
      }
    }, [id])

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={imilePost?.tittle}
        description={imilePost?.tittle}
        pageLocale={pageLocale}
      />
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner */}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            width: '100%',
            height: ['230px', '300px', '550px', 650, '790px'],
            mt: ['55px', '55px', '90px'],
          },
        }}
      >
        <img
          src={imilePost?.detailImageUrl}
          className='bg-img'
        />
      </Box>

      <DocumentMeta {...meta} />

      <Breadcrumbs
        aria-label='breadcrumb'
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          my: ['40px'],
          ...(isPc
            ? { px: 'var(--page-px)', maxWidth: 'var(--page-width)', margin: '0 auto' }
            : {}),
        }}
      >
        <Link underline='hover' color='inherit' href='/blog'>
          Blog
        </Link>
        <Typography color='text.primary'>Blog details</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isPc ? 'row' : 'column',
          ...(isPc
            ? { px: 'var(--page-px)', maxWidth: 'var(--page-width)', margin: '0 auto' }
            : {}),
        }}
      >
        {/* 文章详情 */}
        <Box
          sx={{
            width: '100%',
            marginInlineEnd: '30px',
            ...(!isPc ? { px: 'var(--page-px)', maxWidth: 'var(--page-width)' } : {}),
          }}
        >
          <Box
            sx={{
              mb: ['60px'],
              img: {
                width: '100%',
              },
              '.time': {
                color: '#095ef7',
                fontWeight: '600',
                marginBottom: '20px',
              },
              "iframe":{
                ...(!isPc?{width:'100% !important'}:[])
              }
            }}
          >
            <Box
              component={'h1'}
              sx={{ marginBottom: '36px', color: '#000', fontWeight: '600' }}
            >
              {imilePost?.tittle}
            </Box>
            <p className='time'>{imilePost?.createDateFormart1}</p>
            <Typography
              variant='body1'
              gutterBottom
              component='div'
              dangerouslySetInnerHTML={{ __html: imilePost?.body }}
            ></Typography>
          </Box>
          <LinkForShare
            fillColor='#a6a6b9'
            size={[20, 24]}
            mr={['21px', '33px']}
            anchor={['left']}
            title={imilePost?.tittle}
            url={location.href}
            imageUrl={imilePost?.detailImageUrl}
          />
        </Box>
        {!isPc && (
          <Box
            sx={{
              margin: '60px 0 40px',
            }}
          >
            {!subscribeStatus ? <Subscribe /> : <SubscribeSuccess />}
          </Box>
        )}
        {/* 最新文章 */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            width: 'min-content !important',
            height: 'max-content',
            marginInlineStart: isPc ? 'auto !important' : '',
            ...(isPc ? {} : {ml: 'auto !important',  mr: 'auto !important'}),
            mb: ['52px'],
          }}
        >
          {dataBlog.map((item, index) => (
            <Grid
              key={item.id}
              item
              xs={12}
              sm={12}
              md={12}
              key={index}
              sx={{ px: '10px !important' }}
            >
              <Card
                sx={{
                  width: 320,
                  m: '0 auto',
                  borderRadius: '24px',
                  fontSize: [16],
                  background: '#F6F7FA',
                }}
              >
                <CardMedia
                  component='img'
                  height='180'
                  image={item.coverImageUrl}
                  alt='green iguana'
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='div'
                    sx={{
                      fontSize: [18],
                      color: '#000417',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      '-webkit-line-clamp': '3',
                      '-webkit-box-orient': 'vertical',
                    }}
                  >
                    {item.tittle}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='div'
                    sx={{
                      display: 'flex',
                      fontSize: [16],
                      fontFamily: 'unset',
                      fontWeight: 500,
                      color: '#5b5c73',
                      mt: ['24px'],
                      mb: ['12px'],
                    }}
                  >
                    <div>
                      {' '}
                      {`${item.createDateFormart1.split('-')[2]}/${+item.createDateFormart1.split(
                        '-',
                      )[1]}/${item.createDateFormart1.split('-')[0]}`}
                    </div>
                    <Box sx={{ marginInlineStart: 'auto' }}> {item.categoryName}</Box>
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      fontSize: ['16px'],
                      color: '#797a8d',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      '-webkit-line-clamp': '3',
                      '-webkit-box-orient': 'vertical',
                    }}
                  >
                    {getText(item.body)}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    size='small'
                    type='text'
                    style={{ color: '#095ef7', fontWeight: 600 }}
                    onClick={() => {
                      getListBlogs.run({ trimId: item.id })
                      navigate(`/blog/detail/${item.searchTitle.toLowerCase()}`, {
                        state: { title:item.searchTitle.toLowerCase(), id: item.id },
                      })
                    }}
                  >
                    Read More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {isPc && !subscribeStatus ? (
        <Subscribe />
      ) : isPc && subscribeStatus ? (
        <SubscribeSuccess />
      ) : (
        <></>
      )}

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
})


export const query = graphql`
  query ($language: String!, $searchTitle: String!) {
    locales: allLocale(filter: { ns: { in: ["blog"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    imilePost(searchTitle: {eq: $searchTitle}, language: {eq: $language}) {
      id
      tittle
      sysLanguage
      searchTitle
      body
      createDateFormart1
      createDateFormart2
      detailImageUrl
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`;

export default BlogDetail;
